const mobileNav = document.querySelector('#mobile-nav');
documentNavListener();

/**
 * Listen to document for pointer events, to close nav
 */
function documentNavListener() {
	mobileNav.addEventListener('click', () => {
		event.stopPropagation();
	});
	document.addEventListener('click', closeMobileNav);
}

/**
 * Toggle mobile nav, i.e. menubutton click
 */
function toggleMobileNav() {
	event.stopPropagation();
	if (mobileNav.classList.contains('open')) {
		closeMobileNav();
	} else {
		openMobileNav();
	}
}

function openMobileNav() {
	event.stopPropagation();
	mobileNav.classList.add('open');
}

function closeMobileNav() {
	event.stopPropagation();
	mobileNav.classList.remove('open');
}
